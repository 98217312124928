$(document).on('turbolinks:load', function() {
  $('.categories-dropdown h4').click(function(e) {
      e.stopPropagation();
      $('.category-text').not($(this).closest('.categories-dropdown').find('.category-text')).slideUp();
      $('.hover-style').not($(this).closest('.hover-style')).removeClass('manue-style');
      var $category = $(this).closest('.categories-dropdown');
      $category.find('.category-text').slideToggle();
      $category.find('.hover-style').toggleClass('manue-style');
  });
  $(document).click(function(e) {
      if (!$(e.target).closest('.categories-dropdown').length) {
          $('.category-text').slideUp();
          $('.hover-style').removeClass('manue-style');
      }
  });
});