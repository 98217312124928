$(document).on('turbolinks:load', function() {
  const profileImage = document.querySelector('.profile-image');
  const profileInput = document.getElementById('profile-input');

  profileImage.addEventListener('click', function() {
    profileInput.click();
  });

  profileInput.addEventListener('change', function(event) {
    const [file] = profileInput.files;
    if (file) {
      profileImage.src = URL.createObjectURL(file);
    }
  });
});