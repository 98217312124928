let shareUrl = window.location.href

$(document).on('turbolinks:load', function () {
  $(document).on('click', '.share-icon-card', function(e) {
    e.preventDefault();
    var $this = $(this);
    var productId = $this.data('product-id');
    shareUrl = window.location.origin + "/products/" + productId
    shareProfile()
  });

  $(document).on('click', '.share-icon-single', function(e) {
    e.preventDefault();
    shareUrl = window.location.href
    shareProfile()
  });

  function shareProfile() {
    if (navigator.share) {
      navigator.share({
        title: 'Social Share',
        url: shareUrl
      }).then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      document.getElementById('hiddenModalOpener').click();
    }
  }  
  
  $(document).on('click', '#share-facebook', function(e) {
    e.preventDefault();
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
  });
  
  $(document).on('click', '#share-twitter', function(e) {
    e.preventDefault();
    window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`, '_blank');
  });
  
  $(document).on('click', '#share-linkdin', function(e) {
    e.preventDefault();
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`, '_blank');
  });
  
  $(document).on('click', '#copy-url', function(e) {
    e.preventDefault();
    var $this = $(this);
     
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        $this.text("Copied");
        setTimeout(()=>{
          $this.text("Copy URL");
        },2000)
      })
      .catch((error) => alert('Failed to copy URL'));
  });
  

})

