window.initAutocomplete = function() {
  try {
    var inputElement = document.getElementById('autocomplete');
    if (!inputElement) {
        console.warn("Autocomplete input element not found.");
        return;
    }
    let autocomplete = new google.maps.places.Autocomplete(inputElement, { types: ['geocode'] });
  
    autocomplete.addListener('place_changed', function() {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
            window.alert("No details available for: '" + place.name + "'");
            return;
        }
        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();
  
        document.getElementById('latitude').value = latitude;
        document.getElementById('longitude').value = longitude;
    });
  }
  catch {
    console.log("Error in initAutocomplete")
  }

};

$(document).on('turbolinks:load', function() {
  if (document.getElementById('autocomplete')) {
      window.initAutocomplete();
  }
});