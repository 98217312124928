import { initChatChannel } from '../channels/chat_channel'
import { initNotificationChannel } from '../channels/notification_channel';

document.addEventListener('turbolinks:load', () => {
  initNotificationChannel()
  initChatChannel();
});


$(document).on('turbolinks:load', function() {
    var navHeight = $('.nav-container').height(); 
    var remainingHeight = 'calc(100vh - ' + navHeight + 'px)';
    $('.message-area').css('height', remainingHeight);
})

$(document).on('turbolinks:load', function() {
    $('.ajax-chat-link').click(function(e) {
      e.preventDefault();
      var productId = $(this).data('product-id');
      var userId = $(this).data('user-id');
      $.ajax({
        url: '/chat', 
        type: 'GET',
        dataType: 'script',
        contentType: false,
        data: {
          product_id: productId,
          user_id: userId
        }
      });
    });
});

$(document).on('click', '.chat-icon', function() {
  $(".chatbox").removeClass('showbox');
});

