$(document).on('turbolinks:load', function() {
  $('.region-checkbox').on('change', function() {
      var region = $(this).val();
      var citiesDiv = $(`.cities_for_${region.replace(/[^A-Za-z0-9]/g, '')}`);
      if ($(this).is(':checked')) {
          citiesDiv.css('display', 'flex');
      } else {
          citiesDiv.css('display', 'none');
          citiesDiv.find('.filter-checkbox').prop('checked', false);
      }
  });

  $('.category-checkbox').on('change', function() {
      var category = $(this).val();
      var sub_category_div = $(`.sub_categories_for_${category.replace(/[^A-Za-z0-9]/g, '')}`);
      if ($(this).is(':checked')) {
          sub_category_div.css('display', 'flex');
      } else {
          sub_category_div.css('display', 'none');
          sub_category_div.find('.filter-checkbox').prop('checked', false);
      }
  });

  $('.brand-checkbox').on('change', function() {
      var brand = $(this).val();
      var brand_div = $(`.brand_models_for_${brand.replace(/[^A-Za-z0-9]/g, '')}`);
      if ($(this).is(':checked')) {
          brand_div.css('display', 'flex');
      } else {
          brand_div.css('display', 'none');
          brand_div.find('.filter-checkbox').prop('checked', false);
      }
  });

  $(document).off('click', '#toggle-brands').on('click', '#toggle-brands', function() {
      var isShowingMore = $(this).text() === 'Show More';
      $('.hidden-brands').css('display', isShowingMore ? 'flex' : 'none');
      $(this).text(isShowingMore ? 'Show Less' : 'Show More');
  });

  $(document).off('click', '#toggle-regions').on('click', '#toggle-regions', function() {
      var isShowingMore = $(this).text() === 'Show More';
      $('.hidden-regions').css('display', isShowingMore ? 'flex' : 'none');
      $(this).text(isShowingMore ? 'Show Less' : 'Show More');
  });
});