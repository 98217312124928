// form
$(document).on('turbolinks:load', function() {

    $('#save_for_later').on('click', function() {
      $('#is_publish_field').val('false');
      $('#ad-form').submit();
    });


    let imageCount = $('.images-container').data('image-count');
    let fileInputIndex = 0;

    $('.images-container').on('click', '.delete-image', function() {
        let index = parseInt($(this).data('index'))
        if (!Number.isInteger(index)) {
            return;
        }
        let imageId = $(this).data('image-id').toString();

        if (imageId.startsWith('new-image-') === false) {
            $('#images_to_delete_container').append('<input type="hidden" name="product[images_to_delete][]" value="' + imageId + '">');
        } else {
            inputField = $('input[data-uniq-input="' + imageId + '"]');
            if (inputField){
                inputField.remove()
            }
        }

        $('#image-box-' + index).remove();
        imageCount--;

        $('.images-container .image-box').each(function(i) {
            $(this).find('.delete-image').data('index', i);
            $(this).attr('id', ($(this).hasClass('new-image-box') ? 'new-image-box-' : 'image-box-') + i);
        });

        if ($('.images-container .image-box').length < 12) {
            $('.images-container').append(
                '<div class="image-box new-image-box" id="new-image-box-' + ($('.images-container .image-box').length) + '">' +
                '<i class="fa-solid fa-camera pointer" style="font-size: 30px;"></i></div>'
            );
        }
    });

    $('.images-container').on('change', '.image_input', function() {
        if (this.files && this.files[0]) {
            let reader = new FileReader();
            reader.onload = function(event) {
                let uniqueId = 'new-image-' + fileInputIndex;
                $('#new-image-box-' + imageCount)
                  .removeClass('new-image-box')
                  .attr('id', 'image-box-' + imageCount)
                  .html(
                    '<img src="' + event.target.result + '" style="width: 100%; height: 100%;">' +
                    '<i class="fa-solid fa-xmark fa-lg pointer delete-image" data-image-id="'+ uniqueId + '" data-index="' + fileInputIndex + '"></i>'
                );
                imageCount++;
                fileInputIndex++;
            };
            reader.readAsDataURL(this.files[0]);
        }
    });

    $('.images-container').on('click', '.new-image-box', function() {
        if(imageCount < 11) {
            let newFileInput = $('<input type="file" style="display: none;" accept="image/*" >')
                .attr('name', 'product[gallery][]')
                .attr('data-uniq-input', `new-image-${fileInputIndex}`)
                .attr('class', 'image_input');
            $('.images-container').append(newFileInput);
            newFileInput.trigger('click');
        }
    });

    function updateFieldVisibility(subCategory) {
        $('.motor-field, .car-field, .truck-field, .property-sale-field, .property-rent-field, .land-sale-field').hide();
        switch(subCategory) {
            case 'Motorcycles':
                $('.motor-field').show();
                break;
            case 'Car':
                $('.car-field').show();
                break;
            case 'Heavy vehicles':
                $('.truck-field').show();
                break;
            case 'Property for sale':
                $('.property-sale-field').show();
                break;
            case 'Property for rent':
                $('.property-rent-field').show();
                break;
            case 'Land for sale':
                $('.land-sale-field').show();
                break;
        }
    }

    // function emptyFieldsValue(fieldsToClear){
    //     fieldsToClear.forEach(function(fieldClass) {
    //         $(fieldClass).find('input, select, textarea').val('');
    //     });
    // }

    // function clearUnrelatedFields(subCategory) {
    //     if (!subCategory){
    //         return;
    //     }
    //     let allFields = ['.motor-field', '.car-field', '.truck-field', '.property-sale-field', '.property-rent-field', '.land-sale-field'];
    //     let fieldsToKeep = {
    //         'Motorcycles': ['.motor-field'],
    //         'Car': ['.car-field'],
    //         'Heavy vehicles': ['.truck-field'],
    //         'Property for sale': ['.property-sale-field'],
    //         'Property for rent': ['.property-rent-field'],
    //         'Land for sale': ['.land-sale-field']
    //     };
    //     let fieldsToClear = allFields.filter(function(field) {
    //         return !fieldsToKeep[subCategory].includes(field);
    //     });
    // }

    $('.sub_category').on('change', function() {
        let selectedSubCategory = $(this).val();
        updateFieldVisibility(selectedSubCategory);
    });

    let initialSubCategory = $('.sub_category').val();
    updateFieldVisibility(initialSubCategory);
    $('#ad-form').off('submit.rails');

    function enableDisableSubmitButtons(value, form){
        const submitButtons = form.querySelectorAll('input[type="submit"], button[type="submit"]');
            submitButtons.forEach(function (button) {
            button.disabled = value;
        });
    }

    $('#ad-form').submit(function(e) {
        e.preventDefault();
        cleanGalleryImages()
        enableDisableSubmitButtons(true, this)
        let is_error = checkAdFormErrors();
        if (is_error){
            let subcategory = $('.sub_category').val();
            updateFieldVisibility(subcategory, true);
            setTimeout(() => { enableDisableSubmitButtons(false, this) }, 1000);
        } else {
            this.submit();
        }
    });

    function cleanGalleryImages(){
        $('input[type="file"].image-input').each(function() {
            if (this.files.length === 0) {
                $(this).remove();
            }
        });
    }

    function checkAdFormErrors() {
        let is_error = false;
        let subCategory = $('.sub_category').val();
        $('.error').text('');

        let commonFields = {
            'title': '#ad-form [name="product[title]"]',
            'images': '#ad-form [name="product[images]"]',
            'description': '#ad-form [name="product[description]"]',
            'price': '#ad-form [name="product[price]"]',
            'seller': '#ad-form [name="product[seller]"]',
            'condition': '#ad-form [name="product[condition]"]',
            'region': '#ad-form [name="product[region]"]',
            'city': '#ad-form [name="product[city]"]',
            'category': '#ad-form [name="product[category]"]',
            'location': '#ad-form [name="product[location]"]',
            'sub_category': '#ad-form [name="product[sub_category]"]'
        };

        for (let fieldName in commonFields) {
            if (fieldName === "images"){
                is_error = validateImages()
            } 
            if (fieldName !== "images" && $(commonFields[fieldName]).val() === '') {
                is_error = true
                $(`.${fieldName}-error`).text(PRODUCT_FIELDS_MAPPING[fieldName] + ' is required.');
            }
        }

        let categoryRequiredFields = {
            'Motorcycles': ['.motor-field input', '.motor-field select'],
            'Car': ['.car-field input', '.car-field select'],
            'Heavy vehicles': ['.truck-field input', '.truck-field select'],
            'Property for sale': ['.property-sale-field input', '.property-sale-field select'],
            'Property for rent': ['.property-rent-field input', '.property-rent-field select'],
            'Land for sale': ['.land-sale-field input', '.land-sale-field select']
        };

        if (categoryRequiredFields[subCategory]) {
            categoryRequiredFields[subCategory].forEach(function(selector) {
                $(selector).each(function() {
                    if ($(this).val() === '') {
                        is_error = true
                        let fieldName = extractInputName($(this).attr('name'))
                        if (fieldName){
                           $(`.${fieldName}-error`).text(PRODUCT_FIELDS_MAPPING[fieldName] + ' is required.');
                        }
                    }
                });
            });
        }
        return is_error;
    }
    function validateImages() {
        var existingImagesCount = $('.images-container .image-box:not(.new-image-box)').length;
        if (existingImagesCount === 0) {
            $('.images-error').text('Please select at least one image.');
            return true;
        }
        return false
    }

   function extractInputName(productString) {
        try {
            return productString.split("[")[1].split("]")[0];
        } catch (error) {
            console.error("Error extracting input name:", error);
            return null; 
        }
    }

    function updateModelOptions(selectedBrand) {
        let modelSelect = $('#product_model');
        modelSelect.empty();
        let sortedModels = BRAND_MODELS[selectedBrand].sort();
        sortedModels.push("other");
        if(sortedModels) {
            sortedModels.forEach(function(model) {
                modelSelect.append($('<option>', { 
                    value: model,
                    text : model 
                }));
            });
        }
    }

    function updateCityOptions(selectedCity) {
        let citySelect = $('#product_city');
        citySelect.empty();
        if(REGION_CITIES[selectedCity]) {
            REGION_CITIES[selectedCity].forEach(function(city) {
                citySelect.append($('<option>', { 
                    value: city,
                    text : city 
                }));
            });
        }
    }

    $('#product_region').on('change', function() { 
        let selectedCity = $(this).val();
        updateCityOptions(selectedCity);
    });

    $('#product_brand').on('change', function() { 
        let selectedBrand = $(this).val();
        updateModelOptions(selectedBrand);
    });

    function updateConditionType(category) {
        let conditionTypeSelect = $('#product_condition');
        conditionTypeSelect.empty()

        let categoryTypes = category == "Vehicle" ? VEHICLE_CONDITION_TYPE : CONDITION_TYPE

        categoryTypes.forEach(function(type) {
            conditionTypeSelect.append($('<option>', { 
                value: type,
                text : type 
            }));
        });

    }

    function bindSubCategoryClick() {
        $('.sub-category-btn').on('click', function(e) {
            e.preventDefault();

            $('.sub-category-btn').removeClass('contained-btn-style-dark');
            $(this).addClass('contained-btn-style-dark');

            const selectedSubCategory = $(this).text().trim();
            $('input[name="product[sub_category]"]').val(selectedSubCategory).trigger('change');;

            $('.third-level-categories').empty();
            $('input[name="product[third_level_category]"]').val('');
            if (THIRD_LEVEL_CATEGORIES[selectedSubCategory]) {
                
                $('.third-level-categories').append('<div><label>Third Level Category</label></div>')
                THIRD_LEVEL_CATEGORIES[selectedSubCategory].forEach(function(thirdLevelCat) {
                    $('.third-level-categories').append('<button class="btn category-btn-style mr-3 mt-2 third-level-category-btn" style="margin-right: 10px; margin-top: 10px;">' + thirdLevelCat + '</button>');
                });
                bindThirdLevelCategoryClick();
            }
        });
    }

    function bindThirdLevelCategoryClick() {
        $('.third-level-category-btn').on('click', function(e) {
            e.preventDefault();

            $('.third-level-category-btn').removeClass('contained-btn-style-dark');
            $(this).addClass('contained-btn-style-dark');

            const selectedThirdLevelCategory = $(this).text().trim();
            $('input[name="product[third_level_category]"]').val(selectedThirdLevelCategory);
        });
    }

    $('.category-btn').on('click', function(e) {
        e.preventDefault(); 

        $('.category-btn').removeClass('contained-btn-style-dark');
        $(this).addClass('contained-btn-style-dark');
        const selectedCategory = $(this).text().trim();
        old_category_value = $('input[name="product[category]"]').val()
        if (old_category_value.trim() !== selectedCategory){
           updateConditionType(selectedCategory)
        }
        $('input[name="product[category]"]').val(selectedCategory);
        $('.sub-categories, .third-level-categories').empty();
        $('input[name="product[sub_category]"], input[name="product[third_level_category]"]').val('');
        if (SUB_CATEGORIES[selectedCategory]) {
            $('.sub-categories').append('<div><label>Second Level Category</label></div>')
            SUB_CATEGORIES[selectedCategory].forEach(function(subCat) {
                $('.sub-categories').append('<button class="btn category-btn-style mr-3 mt-2 sub-category-btn" style="margin-right: 10px; margin-top: 10px;">' + subCat + '</button>');
            });
            bindSubCategoryClick();
        }
    });

    function handleCatgoryEditForm() {
        var category = $('input[name="product[category]"]');
        var sub_category = $('input[name="product[sub_category]"]');
        var catgory_value = category.val();
        var sub_catgory_value = sub_category.val();
        if (catgory_value && sub_catgory_value) {
            setTimeout(()=>{
                $('.category-btn').filter(function() {
                    return $(this).text().trim() === catgory_value.trim();
                }).trigger('click');
            }, 50)
            setTimeout(()=>{
                $('.sub-category-btn').filter(function() {
                    return $(this).text().trim() === sub_catgory_value.trim();
                }).trigger('click');
            }, 100)

        }
    }

    setTimeout(()=>{
        handleCatgoryEditForm()
    }, 500)
});
