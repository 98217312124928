window.CATEGORIES = [
    "Vehicle", 
    "Furniture & Interior", 
    "Electronics", 
    "Real estate", 
    "Business & Industries", 
    "Mobiles & Tablet"
];

window.SUB_CATEGORIES = {
    "Vehicle": ["Car", "Motorcycles", "Heavy vehicles"],
    "Real estate": ["Land for sale", "Property for sale", "Property for rent"],
    "Business & Industries": ["Building Materials", "Minerals and Raw materials", "Machines and equipment", "Imported goods"],
    "Furniture & Interior": ["Lighting for sale", "Tables and Chairs", "House decoration", "Wardrobes", "Kitchen equipment", "Beds and Mattresses", "Sofas and Armchairs"],
    "Electronics": ["Computers", "Tv", "Gaming-console", "Camera devices", "White Goods", "Small Kitchen Appliances", "Audio"],
    "Mobiles & Tablet": ["Tablets", "Mobile Accessories", "Mobile Phones"]
};

window.THIRD_LEVEL_CATEGORIES = {
    "Motorcycles": ["Tuck tuck", "Motorcycle", "Scooter", "Moped"],
    "Heavy vehicles": ["Commercial Van", "Trucks", "Bus"],
    "Property for rent": ["Villa", "Apartment", "Room", "Commercial Real Estate", "Office"],
    "Property for sale": ["House and Villa", "Apartment Complex", "Commercial Property"]
};

window.PROPERTY_DOCUMENTATION_TYPE = ["A", "B", "C"]
window.SELLER_TYPE = ['Private', 'Dealer']
window.TRANSMISSION_TYPE = ['Auto', 'Manual']
window.FUEL_TYPE = ['Diesel', 'Petrol']
window.CONDITION_TYPE = ['Used', 'New']
window.VEHICLE_CONDITION_TYPE = ['Used', 'New', 'Imported']

window.REGIONS = [ 'Awdal', 'Bakool', 'Banaadir', 'Bari', 'Bay', 'Galguduud', 'Gedo', 'Hiiraan', 'Jubbada Dhexe', 'Jubbada Hoose', 
            'Mudug', 'Nugaal', 'Sanaag', 'Shabeellaha Dhexe', 'Shabeellaha Hoose', 'Sool', 'Togdheer', 'Woqooyi Galbeed']

window.REGION_CITIES = {
  "Awdal": ["Borama", "Lughaye", "Baki", "Zeylac"],
  "Bakool": ["Tayeeglow", "Yeed", "Waajid", "Rab Dhuure", "Ceel Barde", "Xudur"],
  "Banaadir": ["Mogadishu"],
  "Bari": ["Bandarbayla", "Bossaso", "Caluula", "Qandala", "Qardho", "Iskushuban"],
  "Bay": ["Qasax Dheere", "Baidoa", "Buur Hakaba", "Berdale", "Diinsoor"],
  "Galguduud": ["Cabudwaaq", "Cadaado", "Ceel Dheer", "Dhuusamarreeb", "Ceel Buur"],
  "Gedo": ["Ceel waaq", "Doolow", "Baardheere", "Garbahaarey", "Luuq", "Belet Xaawo"],
  "Hiiraan": ["Belet weyne", "Jalalaqsi"],
  "Jubbada Dhexe": ["Bu’aale", "Saakow", "Jilib"],
  "Jubbada Hoose": ["Afmadow", "Badhaadhe", "Jamaame", "Kismaayo"],
  "Mudug": ["Galdogob", "Gaalkacyo", "Hobyo", "Jariiban", "Xarardheere"],
  "Nugaal": ["Eyl", "Burtinle", "Garoowe"],
  "Sanaag": ["Ceel Afweyn", "Ceerigaabo", "Laasqoray"],
  "Shabeellaha Dhexe": ["Cadale", "Adan Yabaal", "Balcad", "Jowhar"],
  "Shabeellaha Hoose": ["Afgooye", "Sablaale", "Baraawe", "Marka", "Qoryooley", "Kurtunwaarey", "Wanla Weyn"],
  "Sool": ["Caynabo", "Xudun", "Taleex", "Laascaanood"],
  "Togdheer": ["Burco", "Sheikh", "Buuhoodle", "Odweyne"],
  "Woqooyi Galbeed": ["Berbera", "Hargeysa", "Gebiley"]
}

window.PRODUCT_FIELDS_MAPPING = {
  "title": "Title",
  "description": "Description",
  "category": "Category",
  "sub_category": "Second Level Caegory",
  "third_level_category": "Third Level Category",
  "price": "Price",
  "condition": "Condition",
  "brand": "Brand",
  "seller": "Seller",
  "mileage": "Mileage(Km)",
  "fuel_type": "Fuel Type",
  "model_year": "Model Year",
  "useable_area": "Useable Area (m²)",
  "primary_area": "Primary Area (m²)",
  "no_off_bathrooms": "Number off bathrooms",
  "no_off_bedrooms": "Number off bedrooms",
  "document_type": "Document Type",
  "model": "Model",
  "transmission": "Transmission",
  "city": "City",
  "region": "Region",
  "location": "Location, City",
  "is_furniture": "Furniture",
  "is_terrace": "Terrace",
  "is_electricity": "Electricity",
  "is_water_sytem": "Water System",
  "is_internet": "Internet",
  "is_appliances": "Appliances",
  "is_garden": "Garden",
  "is_cable_tv": "Cable TV / Satellite",
  "is_modren_housing": "Modern Housing",
  "is_elevator": "Elevator",
  "is_built_in_water_sytem": "Built-in water system",
  "is_parking": "Garage/Parking Space",
  "is_outdoor_space": "Outdoor Space",
  "is_swimming_pool": "Swimming Pool",
  "is_solar_pannels": "Solar panels on the roof",
  "is_balcony": "Balcony",
  "is_pubic_water": "Public water/sewage",
  "is_security_euipment": "Security Equipment",
  "is_backpack": "Backpack/Tank Bag",
  "is_halmet": "Helmet",
  "is_gps": "GPS/Navigation System",
  "is_cd_player": "CD Player",
  "is_bluetooth": "Bluetooth",
  "is_aux_input": "AUX Input",
  "is_alarm": "Alarm",
  "is_abs_breaking": "ABS Brakes",
  "is_climate_control": "Climate Control",
  "is_power_windows": "Power Windows",
  "is_rareview_camera": "Rearview Camera",
  "is_parking_sensor": "Parking Sensor",
  "is_spare_wheel": "Spare Wheel",
  "is_air_conditioning": "Air Conditioning",
  "is_sunroof": "Sunroof/Moonnroof",
} 

window.BRANDS = ["Rolls Royce","Isuzu","Ferrari","decia","DaiHatSu","Bentley","Aston martin","Seat",
  "Renault","Peugeot","Dacia","Citroën","Opel","Alfa Romeo","Škoda","Chevrolet","Porsche","Honda",
  "Subaru","Mazda","Mitsubishi","Lexus","Toyota","BMW","Volkswagen","Suzuki","Mercedes-Benz","Saab",
  "Audi","Kia","Land Rover","Dodge","Chrysler","Ford","Hummer","Hyundai","Infiniti","Jaguar","Jeep",
  "Nissan","Volvo","Daewoo","Fiat","MINI","Rover","Smart"
]
window.BRAND_MODELS = {
    "Rolls Royce": [],
    "Isuzu": ["D-Max"],
    "Ferrari": [],
    "decia": ["Sandero"],
    "DaiHatSu": [
      "YRV",
      "Terios",
      "Sirion",
      "Rocky",
      "Materia",
      "Gran Max",
      "Charade"
  ],
    "Bentley": [],
    "Aston martin": [],
    "Seat": [
      "Alhambra",
      "Altea",
      "Altea XL",
      "Arosa",
      "Cordoba",
      "Cordoba Vario",
      "Exeo",
      "Ibiza",
      "Ibiza ST",
      "Exeo ST",
      "Leon",
      "Leon ST",
      "Inca",
      "Mii",
      "Toledo"
  ],
    "Renault": [
      "Captur",
      "Clio",
      "Clio Grandtour",
      "Espace",
      "Express",
      "Fluence",
      "Grand Espace",
      "Grand Modus",
      "Grand Scenic",
      "Kadjar",
      "Kangoo",
      "Kangoo Express",
      "Koleos",
      "Laguna",
      "Laguna Grandtour",
      "Latitude",
      "Mascott",
      "Mégane",
      "Mégane CC",
      "Mégane Combi",
      "Mégane Grandtour",
      "Mégane Coupé",
      "Mégane Scénic",
      "Scénic",
      "Talisman",
      "Talisman Grandtour",
      "Thalia",
      "Twingo",
      "Wind",
      "Zoé"
  ],
    "Peugeot": [
      "1007",
      "107",
      "106",
      "108",
      "2008",
      "205",
      "205 Cabrio",
      "206",
      "206 CC",
      "206 SW",
      "207",
      "207 CC",
      "207 SW",
      "306",
      "307",
      "307 CC",
      "307 SW",
      "308",
      "308 CC",
      "308 SW",
      "309",
      "4007",
      "4008",
      "405",
      "406",
      "407",
      "407 SW",
      "5008",
      "508",
      "508 SW",
      "605",
      "806",
      "607",
      "807",
      "Bipper",
      "RCZ"
  ],
    "Dacia": [
      "Dokker",
      "Duster",
      "Lodgy",
      "Logan",
      "Logan MCV",
      "Logan Van",
      "Sandero",
      "Solenza"
  ],
    "Citroën":  [
      "Berlingo",
      "C-Crosser",
      "C-Elissée",
      "C-Zero",
      "C1",
      "C2",
      "C3",
      "C3 Picasso",
      "C4",
      "C4 Aircross",
      "C4 Cactus",
      "C4 Coupé",
      "C4 Grand Picasso",
      "C4 Sedan",
      "C5",
      "C5 Break",
      "C5 Tourer",
      "C6",
      "C8",
      "DS3",
      "DS4",
      "DS5",
      "Evasion",
      "Jumper",
      "Jumpy",
      "Saxo",
      "Nemo",
      "Xantia",
      "Xsara"
  ],
    "Opel": [
      "Agila",
      "Ampera",
      "Antara",
      "Astra",
      "Astra cabrio",
      "Astra caravan",
      "Astra coupé",
      "Calibra",
      "Campo",
      "Cascada",
      "Corsa",
      "Frontera",
      "Insignia",
      "Insignia kombi",
      "Kadett",
      "Meriva",
      "Mokka",
      "Movano",
      "Omega",
      "Signum",
      "Vectra",
      "Vectra Caravan",
      "Vivaro",
      "Vivaro Kombi",
      "Zafira"
  ],
  "Alfa Romeo": [
    "145",
    "146",
    "147",
    "155",
    "156",
    "156 Sportwagon",
    "159",
    "159 Sportwagon",
    "164",
    "166",
    "4C",
    "Brera",
    "GTV",
    "MiTo",
    "Crosswagon",
    "Spider",
    "GT",
    "Giulietta",
    "Giulia"
  ],
  "Škoda":[
    "Favorit",
    "Felicia",
    "Citigo",
    "Fabia",
    "Fabia Combi",
    "Fabia Sedan",
    "Felicia Combi",
    "Octavia",
    "Octavia Combi",
    "Roomster",
    "Yeti",
    "Rapid",
    "Rapid Spaceback",
    "Superb",
    "Superb Combi"
  ], 
  "Chevrolet":[
    "Alero",
    "Aveo",
    "Camaro",
    "Captiva",
    "Corvette",
    "Cruze",
    "Cruze SW",
    "Epica",
    "Equinox",
    "Evanda",
    "HHR",
    "Kalos",
    "Lacetti",
    "Lacetti SW",
    "Lumina",
    "Malibu",
    "Matiz",
    "Monte Carlo",
    "Nubira",
    "Orlando",
    "Spark",
    "Suburban",
    "Tacuma",
    "Tahoe",
    "Trax"
  ],
  "Porsche":[
    "911 Carrera",
    "911 Carrera Cabrio",
    "911 Targa",
    "911 Turbo",
    "924",
    "944",
    "997",
    "Boxster",
    "Cayenne",
    "Cayman",
    "Macan",
    "Panamera"
  ],
  "Honda":[
    "Accord",
    "Accord Coupé",
    "Accord Tourer",
    "City",
    "Civic",
    "Civic Aerodeck",
    "Civic Coupé",
    "Civic Tourer",
    "Civic Type R",
    "CR-V",
    "CR-X",
    "CR-Z",
    "FR-V",
    "HR-V",
    "Insight",
    "Integra",
    "Jazz",
    "Legend",
    "Prelude"
  ],
  "Subaru":[
    "BRZ",
    "Forester",
    "Impreza",
    "Impreza Wagon",
    "Justy",
    "Legacy",
    "Legacy Wagon",
    "Legacy Outback",
    "Levorg",
    "Outback",
    "SVX",
    "Tribeca",
    "Tribeca B9",
    "XV"
  ],
  "Mazda":[
    "121",
    "2",
    "3",
    "323",
    "323 Combi",
    "323 Coupé",
    "323 F",
    "5",
    "6",
    "6 Combi",
    "626",
    "626 Combi",
    "B-Fighter",
    "B2500",
    "BT",
    "CX-3",
    "CX-5",
    "CX-7",
    "CX-9",
    "Demio",
    "MPV",
    "MX-3",
    "MX-5",
    "MX-6",
    "Premacy",
    "RX-7",
    "RX-8",
    "Xedox 6"
  ],
  "Mitsubishi":[
    "3000 GT",
    "ASX",
    "Carisma",
    "Colt",
    "Colt CC",
    "Eclipse",
    "Fuso canter",
    "Galant",
    "Galant Combi",
    "Grandis",
    "L200",
    "L200 Pick up",
    "L200 Pick up Allrad",
    "L300",
    "Lancer",
    "Lancer Combi",
    "Lancer Evo",
    "Lancer Sportback",
    "Outlander",
    "Pajero",
    "Pajeto Pinin",
    "Pajero Pinin Wagon",
    "Pajero Sport",
    "Pajero Wagon",
    "Space Star"
  ],
  "Lexus":[
    "CT",
    "GS",
    "GS 300",
    "GX",
    "IS",
    "IS 200",
    "IS 250 C",
    "IS-F",
    "LS",
    "LX",
    "NX",
    "RC F",
    "RX",
    "RX 300",
    "RX 400h",
    "RX 450h",
    "SC 430"
  ],
  "Toyota":[
    "4-Runner",
    "Auris",
    "Avensis",
    "Avensis Combi",
    "Avensis Van Verso",
    "Aygo",
    "Camry",
    "Carina",
    "Celica",
    "Corolla",
    "Corolla Combi",
    "Corolla sedan",
    "Corolla Verso",
    "FJ Cruiser",
    "GT86",
    "Hiace",
    "Hiace Van",
    "Highlander",
    "Hilux",
    "Land Cruiser",
    "MR2",
    "Paseo",
    "Picnic",
    "Prius",
    "RAV4",
    "Sequoia",
    "Starlet",
    "Supra",
    "Tundra",
    "Urban Cruiser",
    "Verso",
    "Yaris",
    "Yaris Verso"
  ],
  "BMW":[
    "i3",
    "i8",
    "M3",
    "M4",
    "M5",
    "M6",
    "Rad 1",
    "Rad 1 Cabrio",
    "Rad 1 Coupé",
    "Rad 2",
    "Rad 2 Active Tourer",
    "Rad 2 Coupé",
    "Rad 2 Gran Tourer",
    "Rad 3",
    "Rad 3 Cabrio",
    "Rad 3 Compact",
    "Rad 3 Coupé",
    "Rad 3 GT",
    "Rad 3 Touring",
    "Rad 4",
    "Rad 4 Cabrio",
    "Rad 4 Gran Coupé",
    "Rad 5",
    "Rad 5 GT",
    "Rad 5 Touring",
    "Rad 6",
    "Rad 6 Cabrio",
    "Rad 6 Coupé",
    "Rad 6 Gran Coupé",
    "Rad 7",
    "Rad 8 Coupé",
    "X1",
    "X3",
    "X4",
    "X5",
    "X6",
    "Z3",
    "Z3 Coupé",
    "Z3 Roadster",
    "Z4",
    "Z4 Roadster"
  ],
  "Volkswagen":[
    "Amarok",
    "Beetle",
    "Bora",
    "Bora Variant",
    "Caddy",
    "Caddy Van",
    "Life",
    "California",
    "Caravelle",
    "CC",
    "Crafter",
    "Crafter Van",
    "Crafter Kombi",
    "CrossTouran",
    "Eos",
    "Fox",
    "Golf",
    "Golf Cabrio",
    "Golf Plus",
    "Golf Sportvan",
    "Golf Variant",
    "Jetta",
    "LT",
    "Lupo",
    "Multivan",
    "New Beetle",
    "New Beetle Cabrio",
    "Passat",
    "Passat Alltrack",
    "Passat CC",
    "Passat Variant",
    "Passat Variant Van",
    "Phaeton",
    "Polo",
    "Polo Van",
    "Polo Variant",
    "Scirocco",
    "Sharan",
    "T4",
    "T4 Caravelle",
    "T4 Multivan",
    "T5",
    "T5 Caravelle",
    "T5 Multivan",
    "T5 Transporter Shuttle",
    "Tiguan",
    "Touareg",
    "Touran"
  ],
  "Suzuki":[
    "Alto",
    "Baleno",
    "Baleno kombi",
    "Grand Vitara",
    "Grand Vitara XL-7",
    "Ignis",
    "Jimny",
    "Kizashi",
    "Liana",
    "Samurai",
    "Splash",
    "Swift",
    "SX4",
    "SX4 Sedan",
    "Vitara",
    "Wagon R+"
  ],
  "Mercedes-Benz":[
    "100 D",
    "115",
    "124",
    "126",
    "190",
    "190 D",
    "190 E",
    "200 - 300",
    "200 D",
    "200 E",
    "210 Van",
    "210 kombi",
    "310 Van",
    "310 kombi",
    "230 - 300 CE Coupé",
    "260 - 560 SE",
    "260 - 560 SEL",
    "500 - 600 SEC Coupé",
    "Trieda A",
    "A",
    "A L",
    "AMG GT",
    "Trieda B",
    "Trieda C",
    "C",
    "C Sportcoupé",
    "C T",
    "Citan",
    "CL",
    "CL",
    "CLA",
    "CLC",
    "CLK Cabrio",
    "CLK Coupé",
    "CLS",
    "Trieda E",
    "E",
    "E Cabrio",
    "E Coupé",
    "E T",
    "Trieda G",
    "G Cabrio",
    "GL",
    "GLA",
    "GLC",
    "GLE",
    "GLK",
    "Trieda M",
    "MB 100",
    "Trieda R",
    "Trieda S",
    "S",
    "S Coupé",
    "SL",
    "SLC",
    "SLK",
    "SLR",
    "Sprinter"
  ],
  "Saab":[
    "9-3",
    "9-3 Cabriolet",
    "9-3 Coupé",
    "9-3 SportCombi",
    "9-5",
    "9-5 SportCombi",
    "900",
    "900 C",
    "900 C Turbo",
    "9000"
  ],
  "Audi":[
    "100",
    "100 Avant",
    "80",
    "80 Avant",
    "80 Cabrio",
    "90",
    "A1",
    "A2",
    "A3",
    "A3 Cabriolet",
    "A3 Limuzina",
    "A3 Sportback",
    "A4",
    "A4 Allroad",
    "A4 Avant",
    "A4 Cabriolet",
    "A5",
    "A5 Cabriolet",
    "A5 Sportback",
    "A6",
    "A6 Allroad",
    "A6 Avant",
    "A7",
    "A8",
    "A8 Long",
    "Q3",
    "Q5",
    "Q7",
    "R8",
    "RS4 Cabriolet",
    "RS4/RS4 Avant",
    "RS5",
    "RS6 Avant",
    "RS7",
    "S3/S3 Sportback",
    "S4 Cabriolet",
    "S4/S4 Avant",
    "S5/S5 Cabriolet",
    "S6/RS6",
    "S7",
    "S8",
    "SQ5",
    "TT Coupé",
    "TT Roadster",
    "TTS"
  ],
  "Kia":[
    "Avella",
    "Besta",
    "Carens",
    "Carnival",
    "Cee`d",
    "Cee`d SW",
    "Cerato",
    "K 2500",
    "Magentis",
    "Opirus",
    "Optima",
    "Picanto",
    "Pregio",
    "Pride",
    "Pro Cee`d",
    "Rio",
    "Rio Combi",
    "Rio sedan",
    "Sephia",
    "Shuma",
    "Sorento",
    "Soul",
    "Sportage",
    "Venga"
  ],
  "Land Rover": [
    "109",
    "Defender",
    "Discovery",
    "Discovery Sport",
    "Freelander",
    "Range Rover",
    "Range Rover Evoque",
    "Range Rover Sport"
  ],
  "Dodge":[
    "Avenger",
    "Caliber",
    "Challenger",
    "Charger",
    "Grand Caravan",
    "Journey",
    "Magnum",
    "Nitro",
    "RAM",
    "Stealth",
    "Viper"
  ],
  "Chrysler":[
    "300 C",
    "300 C Touring",
    "300 M",
    "Crossfire",
    "Grand Voyager",
    "LHS",
    "Neon",
    "Pacifica",
    "Plymouth",
    "PT Cruiser",
    "Sebring",
    "Sebring Convertible",
    "Stratus",
    "Stratus Cabrio",
    "Town & Country",
    "Voyager"
  ],
  "Ford":[
    "Aerostar",
    "B-Max",
    "C-Max",
    "Cortina",
    "Cougar",
    "Edge",
    "Escort",
    "Escort Cabrio",
    "Escort kombi",
    "Explorer",
    "F-150",
    "F-250",
    "Fiesta",
    "Focus",
    "Focus C-Max",
    "Focus CC",
    "Focus kombi",
    "Fusion",
    "Galaxy",
    "Grand C-Max",
    "Ka",
    "Kuga",
    "Maverick",
    "Mondeo",
    "Mondeo Combi",
    "Mustang",
    "Orion",
    "Puma",
    "Ranger",
    "S-Max",
    "Sierra",
    "Street Ka",
    "Tourneo Connect",
    "Tourneo Custom",
    "Transit",
    "Transit",
    "Transit Bus",
    "Transit Connect LWB",
    "Transit Courier",
    "Transit Custom",
    "Transit kombi",
    "Transit Tourneo",
    "Transit Valnik",
    "Transit Van",
    "Transit Van 350",
    "Windstar"
  ],
  "Hummer":[
    "H2",
    "H3"
  ],
  "Hyundai": [
    "Accent",
    "Atos",
    "Atos Prime",
    "Coupé",
    "Elantra",
    "Galloper",
    "Genesis",
    "Getz",
    "Grandeur",
    "H 350",
    "H1",
    "H1 Bus",
    "H1 Van",
    "H200",
    "i10",
    "i20",
    "i30",
    "i30 CW",
    "i40",
    "i40 CW",
    "ix20",
    "ix35",
    "ix55",
    "Lantra",
    "Matrix",
    "Santa Fe",
    "Sonata",
    "Terracan",
    "Trajet",
    "Tucson",
    "Veloster"
  ],
  "Infiniti": [
    "EX",
    "FX",
    "G",
    "G Coupé",
    "M",
    "Q",
    "QX"
  ],
  "Jaguar": [
    "Daimler",
    "F-Pace",
    "F-Type",
    "S-Type",
    "Sovereign",
    "X-Type",
    "X-type Estate",
    "XE",
    "XF",
    "XJ",
    "XJ12",
    "XJ6",
    "XJ8",
    "XJ8",
    "XJR",
    "XK",
    "XK8 Convertible",
    "XKR",
    "XKR Convertible"
  ],
  "Jeep": [
    "Cherokee",
    "Commander",
    "Compass",
    "Grand Cherokee",
    "Patriot",
    "Renegade",
    "Wrangler"
  ],
  "Nissan": [
    "100 NX",
    "200 SX",
    "350 Z",
    "350 Z Roadster",
    "370 Z",
    "Almera",
    "Almera Tino",
    "Cabstar E - T",
    "Cabstar TL2 Valnik",
    "e-NV200",
    "GT-R",
    "Insterstar",
    "Juke",
    "King Cab",
    "Leaf",
    "Maxima",
    "Maxima QX",
    "Micra",
    "Murano",
    "Navara",
    "Note",
    "NP300 Pickup",
    "NV200",
    "NV400",
    "Pathfinder",
    "Patrol",
    "Patrol GR",
    "Pickup",
    "Pixo",
    "Primastar",
    "Primastar Combi",
    "Primera",
    "Primera Combi",
    "Pulsar",
    "Qashqai",
    "Serena",
    "Sunny",
    "Terrano",
    "Tiida",
    "Trade",
    "Vanette Cargo",
    "X-Trail"
  ],
  "Volvo": [
    "240",
    "340",
    "360",
    "460",
    "850",
    "850 kombi",
    "C30",
    "C70",
    "C70 Cabrio",
    "C70 Coupé",
    "S40",
    "S60",
    "S70",
    "S80",
    "S90",
    "V40",
    "V50",
    "V60",
    "V70",
    "V90",
    "XC60",
    "XC70",
    "XC90"
  ],
  "Daewoo": [
    "Espero",
    "Kalos",
    "Lacetti",
    "Lanos",
    "Leganza",
    "Lublin",
    "Matiz",
    "Nexia",
    "Nubira",
    "Nubira kombi",
    "Racer",
    "Tacuma",
    "Tico"
  ],
  "Fiat": [
    "1100",
    "126",
    "500",
    "500L",
    "500X",
    "850",
    "Barchetta",
    "Brava",
    "Cinquecento",
    "Coupé",
    "Croma",
    "Doblo",
    "Doblo Cargo",
    "Doblo Cargo Combi",
    "Ducato",
    "Ducato Van",
    "Ducato Kombi",
    "Ducato Podvozok",
    "Florino",
    "Florino Combi",
    "Freemont",
    "Grande Punto",
    "Idea",
    "Linea",
    "Marea",
    "Marea Weekend",
    "Multipla",
    "Palio Weekend",
    "Panda",
    "Panda Van",
    "Punto",
    "Punto Cabriolet",
    "Punto Evo",
    "Punto Van",
    "Qubo",
    "Scudo",
    "Scudo Van",
    "Scudo Kombi",
    "Sedici",
    "Seicento",
    "Stilo",
    "Stilo Multiwagon",
    "Strada",
    "Talento",
    "Tipo",
    "Ulysse",
    "Uno",
    "X1/9"
  ],
  "MINI": [
    "Cooper",
    "Cooper Cabrio",
    "Cooper Clubman",
    "Cooper D",
    "Cooper D Clubman",
    "Cooper S",
    "Cooper S Cabrio",
    "Cooper S Clubman",
    "Countryman",
    "Mini One",
    "One D"
  ],
  "Rover": [
    "200",
    "214",
    "218",
    "25",
    "400",
    "414",
    "416",
    "620",
    "75"
  ],
  "Smart": [
    "Cabrio",
    "City-Coupé",
    "Compact Pulse",
    "Forfour",
    "Fortwo cabrio",
    "Fortwo coupé",
    "Roadster"
  ],
  "other": []
  }
