$(document).on('turbolinks:load', function() {
  $('.nav-search-icon').click(function(e) {
    const value = $('#nav-search').val();
    if (value) {
      if (window.location.pathname.includes('/products')) {
        $('#hidden-search').val($(this).val());
        $('.btn.contained-btn').click();
      } else {
        window.location.href = `/products?search=${value}`;
      }
    }
  });
});