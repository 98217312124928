import consumer from "./consumer"

let currentSubscription;

const initChatChannel = () => {

  const subscribeToRoom = (room_id, current_user_id) => {
    if (currentSubscription) {
      consumer.subscriptions.remove(currentSubscription);
      currentSubscription = null;
    }
  
    currentSubscription = consumer.subscriptions.create({ channel: "ChatChannel", room_id: room_id, current_user_id: current_user_id }, {
      connected() {
        console.log(`Connected to room ${room_id}`);
      },
  
      disconnected() {
        console.log(`DisConnected to room ${room_id}`);
      },
  
      received(data) {
        if (data.message.body){
          const messagesUl = document.querySelector(".messages-ul");
          const liElement = document.createElement("li");
          liElement.className = data.user_id == current_user_id ? "repaly" : "sender";
          liElement.innerHTML = `<p>${data.message.body}</p>`;
          messagesUl.appendChild(liElement);
          scrollToBottom()
        }
      }
    });
  };

    document.querySelectorAll('.room-select-link').forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const room_id = e.currentTarget.dataset.roomId;
        const current_user_id = document.body.dataset.currentUserId;

        subscribeToRoom(room_id, current_user_id);
      });
    });

    setTimeout(()=>{
      const activeLink = document.querySelector('.room-select-link.active-chat-block');
      if (activeLink) {
        activeLink.click();
      }
    }, 200)

  
    if (window.location.pathname.includes('/chat')) {
      $(document).on('click', '#send-button', function(e) {
        e.preventDefault();
        sendMessage();
      });
  
      $('body').keydown(function(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          sendMessage();
        }
      });
    }
    
    function sendMessage() {
      const messageInput = document.querySelector('#message_content');
      if (messageInput && currentSubscription) {
        const message = messageInput.value.trim();
        if (message) {
          const identifier = currentSubscription.identifier;
          const parsedIdentifier = JSON.parse(identifier);
          const currentChannelRoomId = parsedIdentifier.room_id;
          currentSubscription.perform('speak', { message: message, room_id: currentChannelRoomId });
          messageInput.value = '';
        }
      }
    }
    
};

export { initChatChannel };
