// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@popperjs/core")
require("jquery")
import "bootstrap"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "shared/auto_complete"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "shared/select2"
import "shared/whole_slider"
import "shared/filters"
import "shared/social_share"
import "layout_components/flash"
import "layout_components/nav"
import "./constants"
import "products/products"
import "products/categories"
import "products/single_product"
import "chat/chat"
import "shared/img_enlarge"
import "profile/profile"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.scrollToBottom = function() {
  setTimeout(() => {
    $('.modal-body').each(function() {
      $(this).scrollTop(this.scrollHeight);
    });
  }, 0);
}
