$(document).on('turbolinks:load', function() {

    // server side flashes
    setTimeout(function() {
        var flashMessages = document.querySelectorAll('.toaster-wrapper');
        flashMessages.forEach(function(message) {
            message.remove();
        });
    }, 5000); // 5000 milliseconds = 5 seconds

    // client side flashes
    function addFlashMessage(type, message) {
        var flashWrapper = document.createElement('div');
        flashWrapper.className = `toaster-wrapper ${type}`;
        var iconHTML = '';

        if (type === 'success') {
            iconHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>`;
        } else if (type === 'error') {
            iconHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                        </svg>`;
        } else if (type === 'danger') {
            iconHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>`;
        } else if (type === 'alert') {
            iconHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/>
                        </svg>`
        }

        flashWrapper.innerHTML = `
            <span></span>
            ${iconHTML}
            <div class="message-wrapper">
                <p class="title">${type.charAt(0).toUpperCase() + type.slice(1)}</p>
                <p class="message">${message}</p>
            </div>
        `;
    
        var allToaster = document.querySelector('.all-toaster');
        if (!allToaster) {
            allToaster = document.createElement('div');
            allToaster.className = 'all-toaster';
            document.body.appendChild(allToaster);
        }
        allToaster.appendChild(flashWrapper);
    
        setTimeout(function() {
            allToaster.removeChild(flashWrapper);
        }, 5000);
    }
});
