$(document).on('turbolinks:load', function() {
    document.querySelectorAll('.image-selector').forEach(item => {
        item.addEventListener('click', event => {
            let selectedId = event.currentTarget.getAttribute('data-id');
            let carousel = document.getElementById('carouselExampleInterval');
            
            // Remove active class from current active item
            carousel.querySelector('.carousel-item.active').classList.remove('active');
            
            // Add active class to the selected item
            carousel.querySelector(`.carousel-item[data-id="${selectedId}"]`).classList.add('active');
        });
    });
})