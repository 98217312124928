import consumer from "./consumer"

let currentNotificationSubscription;

const initNotificationChannel = () => {
  const current_user_id = document.body.dataset.currentUserId;

  const subscribeToNotification = (current_user_id) => {
    if (currentNotificationSubscription) {
      consumer.subscriptions.remove(currentNotificationSubscription);
      currentNotificationSubscription = null;
    }
  
    currentNotificationSubscription = consumer.subscriptions.create({ channel: "NotificationChannel", current_user_id: current_user_id }, {
      connected() {
        console.log(`Connected to notification chnnel noti_${current_user_id}`);
      },
  
      disconnected() {
        console.log(`DisConnected to notification chnnel noti_${current_user_id}`);
      },
  
      received(data) {
        console.log("New message:", data);
        $("#unread-title-"+data.conversation_id).css("font-weight", "600")
        $("#unread-para-"+data.conversation_id).css("font-weight", "600")
        $("#unread-noti-"+data.conversation_id).css("display", "block")
      }
    });
  };

  if (window.location.pathname === '/chat') {
    subscribeToNotification(current_user_id)
  }

};

export { initNotificationChannel };
