$(document).on('turbolinks:load', function() {
    const accordionButtons = document.querySelectorAll('.accordion-button');
    accordionButtons.forEach(button => {
        updateAccordionIcon(button);
        button.addEventListener('click', function() {
            updateAccordionIcon(button);
        });
    });
});

function updateAccordionIcon(button) {
    if (button.classList.contains('collapsed')) {
        if (button.querySelector('::after'))
           button.querySelector('::after').style.content = '"\f078"';
    } else {
        if (button.querySelector('::after'))
           button.querySelector('::after').style.content = '"\f077"';
    }
}

$(document).on('turbolinks:load', function() {
    $('.product-fav').click(function(e) {
        e.preventDefault();

        var $this = $(this);
        var productId = $this.data('product-id');
        const current_user_id = document.body.dataset.currentUserId;
        if (current_user_id === "0"){
            window.location.href = window.location.origin + "/users/sign_up"
        }
        $.ajax({
            url: `/toggle_favorite/${productId}`, 
            type: 'POST',
            dataType: 'json', 
            contentType: false,
            data: {},
            success: function(data) {
                $this.removeClass("fa-solid fa-regular");
                if (data.is_favorite) {
                    $this.addClass("fa-solid");
                } else {
                    $this.addClass("fa-regular");
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log('Error:', textStatus, errorThrown);
            }
        });
    });
});
